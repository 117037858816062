@import url("https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@0,300;0,400;1,300;1,400;1,700&family=Poppins:ital,wght@0,200;0,300;0,400;0,500;1,300;1,400&display=swap");

.client-title {
  font: normal bold 45px/1 "Poppins";
  text-align: center;
  letter-spacing: 1px;
  margin-bottom: 6rem;
}
.client-main {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 6rem;
  margin-bottom: 8rem;
}
.client-card {
  width: 300px;
  background-color: #90caf9;
  text-align: center;
  border: 2px solid #e7e7e7;
  border-bottom-right-radius: 50px;
}
.client-card img {
  max-width: 100%;
  height: 150px;
  object-fit: contain;
  width: 300px;
  background-color: white;
  padding-bottom: 20px;
  padding-top: 20px;
}
.client-card h3 {
  padding: 10px;
  font: normal bold 25px/1 "poppins";
  letter-spacing: 1px;
}
.client-card p {
  font: normal normal 18px/30px "poppins";
  padding-right: 10px;
}
.client-button {
  padding: 15px 35px;
  margin-top: 1rem;
  margin-bottom: 2rem;
  outline: none;
  font: normal 15px/1 "poppins";
  letter-spacing: 1px;
  background-color: black;
  color: whitesmoke;
  border: none;
  cursor: pointer;
}
.client-button a {
  color: white;
  text-decoration: none;
}

/* Media Querry */
@media only screen and (max-width: 1060px) {
  .clients {
    margin-top: 6rem;
  }
  .client-title {
    margin-top: -30px;
    margin-bottom: 60px;
    font: normal bold 35px/1 "Poppins";
  }
}
