@import url("https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@0,300;0,400;1,300;1,400;1,700&family=Poppins:ital,wght@0,200;0,300;0,400;0,500;1,300;1,400&display=swap");

.footer-flex {
  background-color: black;
  border-top: 2px solid black;
  margin-top: 1px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
}
.footer-left-flex {
  width: 500px;
}
.footer-company-name {
  color: white;
  text-transform: uppercase;
  font: normal normal bold 33px/55px "Poppins";
  margin-top: 73px;
  letter-spacing: 2px;
}
.footer-left-flex img {
  width: 140px;
  object-fit: contain;
  border-radius: 50px;
}
.get-in-touch {
  color: white;
  font: normal normal bold 25px/1 "Poppins";
  letter-spacing: 2.5px;
  text-transform: uppercase;
}
.company-name {
  color: white;
  font: normal normal bold 25px/50px "Poppins";
  letter-spacing: 2.5px;
  text-transform: uppercase;
}
.company-profile {
  color: white;
  font: normal normal 20px/55px "Poppins";
}
.footer-middle-flex a {
  color: white;
  font: normal normal 20px/45px "Poppins";
  transition: 1s all ease;
  cursor: pointer;
  text-decoration: none;
}
.footer-middle-flex ul {
  margin-left: -30px;
  list-style: none;
}
.footer-right-flex {
  margin-top: 1.7rem;
}
.footer-text {
  font: normal normal 18px/1 "Poppins";
  color: white;
  letter-spacing: 2px;
}
.footer-text-address {
  color: white;
  font: normal normal normal 18px/35px "poppins";
}
.contact-details {
  display: flex;
  align-items: center;
  width: 100%;
  gap: 15px;
}
.footer-icon {
  color: white;
  margin-right: 12px;
  font-size: 18px;
}

/* Footer Bottom */

.footer-bottom {
  background-color: black;
  padding: 1rem 0;
}
.horizontal-line {
  color: white;
  width: 85%;
}
.social-icons {
  color: white;
  font-size: 30px;
  padding: 10px;
  border: 2px solid white;
  border-radius: 50px;
  margin-right: 10px;
  transition: 0.3s all ease-in-out;
}
.social-icons:hover {
  transform: scale(1.1);
}
.social-links {
  display: flex;
  margin-top: 3rem;
  margin-bottom: 2rem;
  justify-content: center;
}
.copyright {
  font: normal normal 18px/1 "poppins";
  letter-spacing: 1px;
  text-align: center;
  color: white;
}
.copyright a {
  cursor: pointer;
  color: aqua;
  text-decoration: none;
  transition: 0.5s all ease;
}
.copyright a:hover {
  color: greenyellow;
}

.footer-middle-flex a:hover {
  color: cyan;
  text-decoration: underline;
}

/* Media Querry */

@media only screen and (max-width: 760px) {
  .footer-left-flex {
    width: 250px;
    text-align: center;
  }
  .footer-company-name {
    font: normal normal bold 22px/35px "Poppins";
    margin-top: 40px;
  }
  .company-profile {
    font: normal normal 18px/40px "Poppins";
  }
  .company-name {
    font: normal normal bold 22px/1 "poppins";
  }
  .footer-middle-flex a {
    font: normal normal 20px/40px "Poppins";
    text-align: center;
  }
  .get-in-touch {
    font: normal bold 22px/1 "Poppins";
    text-align: center;
  }
  .social-media {
    text-align: center;
  }
  .footer-flex {
    flex-direction: row;
    flex: 1;
  }
  .contact-details {
    gap: 5px;
  }
  .footer-right-flex {
    width: 100%;
    margin: 10px;
  }
  .footer-text {
    font: normal 17px/1 "poppins";
    letter-spacing: 0px;
  }
  .footer-text-address {
    font: normal normal 16px/35px "Poppins";
  }
  .social-links {
    flex-wrap: wrap;
  }
  .social-icons {
    font-size: 29px;
    padding: 5px;
  }
  .copyright {
    font: normal 16px/25px "poppins";
  }
}
