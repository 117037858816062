@import url("https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@0,300;0,400;1,300;1,400;1,700&family=Poppins:ital,wght@0,200;0,300;0,400;0,500;1,300;1,400&display=swap");
.services {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.service-main-img {
  width: 100%;
  object-fit: cover;
  height: 50vh;
  border-bottom-right-radius: 150px;
}
.services-head {
  text-align: center;
  font: normal normal bold 45px/1 "poppins";
  letter-spacing: 1px;
  transition: 1s all ease;
}
.service-card {
  width: 400px;
  cursor: pointer;
  flex-wrap: wrap;
  padding: 35px;
  margin: 50px;
  height: fit-content;
  position: relative;
  border: 1px solid #e7e7e7;
  border-radius: 10px;
  background-color: white;
  transition: 0.3s all ease;
  text-align: justify;
  box-shadow: 0 9px 15px 9px rgb(0 94 244 / 15%);
  position: relative;
}
.service-card:hover {
  transform: scale(1.1);
}
.web-logo-service {
  display: flex;
  justify-content: center;
  font-size: 75px;
}
.card-title {
  text-align: center;
  font: normal bold 30px "poppins";
  letter-spacing: 1px;
}
.card-content {
  font: normal normal 20px "poppins";
  line-height: 40px;
}
.card-button {
  outline: none;
  padding: 15px 38px;
  letter-spacing: 1px;
  cursor: pointer;
  font: normal normal 18px "poppins";
  border: 1px solid #e9ecef;
  transition: 1s all ease;
}
.card-button:hover {
  background-image: linear-gradient(to left, #c33764, #1d2671);
  color: white;
  border: none;
}
.button-main {
  display: flex;
  justify-content: center;
  margin-top: 65px;
  margin-bottom: 30px;
}

/* Media Querry */

@media only screen and (max-width: 760px) {
  .services-head {
    font: normal normal bold 35px/1 "Poppins";
  }
  .service-card {
    margin: 20px;
  }
}
