@import url("https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@0,300;0,400;1,300;1,400;1,700&family=Poppins:ital,wght@0,200;0,300;0,400;0,500;1,300;1,400&display=swap");

.Navbar {
  height: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.nav-logo-image {
  width: 150px;
  height: 100px;
  object-fit: cover;
  margin-left: 30px;
}
.nav-top-content {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  padding-top: 5px;
}
.nav-logo {
  font: normal normal bold 28px "Poppins";
  color: #0e1843;
}
.contact-btn {
  background-color: black;
  color: white;
  border: none;
  padding: 15px 20px;
  letter-spacing: 1px;
  font: normal normal 18px "Poppins";
}
.Navbar > .nav-items > a {
  color: black;
  font: normal normal 20px/1 "Poppins";
  letter-spacing: 1px;
  text-decoration: none;
  margin: 20px;
  position: relative;
}
.Navbar > .nav-items {
  margin-right: 20px;
}
.Navbar > .nav-items > a:hover {
  color: rgb(14, 74, 240);
  transition: all 0.5s ease;
}
.Navbar > .nav-items > a::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 0;
  height: 2px;
  transition: all 0.45s;
}
.Navbar > .nav-items > a:hover::before {
  width: 100%;
}
.Navbar > .nav-toggle {
  display: none;
}
.nav-items a {
  position: relative;
  list-style: none;
}
.nav-items a::after {
  content: "";
  position: absolute;
  background-color: black;
  height: 3px;
  border-radius: 4px;
  width: 0%;
  top: 33px;
  left: 0;
  bottom: -4px;
  transition: 0.5s all ease-in-out;
}
.nav-items a:hover:after {
  width: 100%;
}

/* Media Querry */

@media (max-width: 960px) {
  .Navbar > .nav-items {
    position: absolute;
    top: -10px;
    display: flex;
    flex-direction: column;
    background: black;
    border-radius: 15px;
    width: 75%;
    height: 100%;
    color: white;
    transform: translateY(-100%);
    transition: all 0.45s;
    margin-top: 0px;
    text-align: center;
    gap: 15px;
    z-index: 11111;
    transition: 0.5s all ease-out;
    margin-top: 25px;
  }
  .nav-bottom {
    display: none;
  }
  .nav-logo-image {
    background-color: white;
    border-radius: 20px;
  }
  .Navbar {
    background-color: black;
    margin-bottom: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .Navbar > .nav-items > a {
    color: white;
  }
  .Navbar > .nav-items.open {
    transform: translateX(0);
  }
  .Navbar > .nav-toggle {
    display: flex;
    width: 50px;
    height: 40px;
    margin-right: 20px;
    padding-top: 5px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    opacity: 0.8;
    border-radius: 25px;
    z-index: 11111;
  }
  .nav-toggle > .bar {
    position: relative;
    width: 32px;
    height: 2px;
    background: #ffffff;
    transition: all 0.45s ease-in-out;
  }
  .nav-toggle > .bar::before,
  .nav-toggle > .bar::after {
    content: "";
    position: absolute;
    height: 2px;
    background: #ffffff;
    border-radius: 2px;
    transition: all 0.45s ease-in-out;
  }
  .nav-toggle > .bar::before {
    width: 25px;
    transform: translateY(-8px);
    right: 0;
  }
  .nav-toggle > .bar::after {
    width: 32px;
    transform: translateX(8px);
  }
  .nav-toggle.open > .bar {
    transform: translateX(-40px);
    background: transparent;
  }
  .nav-toggle.open > .bar::before {
    width: 32px;
    transform: rotate(45deg) translate(26px, -26px);
  }
  .nav-toggle.open > .bar::after {
    transform: rotate(-45deg) translate(26px, 26px);
  }
}
