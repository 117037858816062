@import url("https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@0,300;0,400;1,300;1,400;1,700&family=Poppins:ital,wght@0,200;0,300;0,400;0,500;1,300;1,400&display=swap");

.testing-top-section {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 30px;
}
.testing-main {
  width: 350px;
  border: 2px solid #e7e7e7;
  border-radius: 15px;
  padding: 30px;
  margin: 10px;
}
.testing-img {
  background-color: #1a237e;
  font-size: 90px;
  color: white;
  border-radius: 10px;
  text-align: center;
  margin-bottom: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.testing-title {
  font: normal normal bold 26px/34px "poppins";
  letter-spacing: 1px;
  text-align: center;
}
.testing-content {
  font: normal normal 20px/40px "poppins";
  text-align: justify;
}
.testing-img-two {
  background-color: #1a237e;
  font-size: 70px;
  padding: 10px;
  color: white;
  border-radius: 10px;
  text-align: center;
  margin-bottom: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.what-we-do {
  padding: 20px;
  border-radius: 150px;
  background-color: #1a237e;
  margin: 5rem 25rem;
}
.what-title {
  font: normal normal bold 30px/1 "poppins";
  color: white;
  letter-spacing: 2px;
  text-align: center;
}
.what-content {
  font: normal normal normal 20px/30px "poppins";
  color: whitesmoke;
  letter-spacing: 1px;
  text-align: center;
}

/* Media Querry */

@media only screen and (max-width: 760px) {
  .what-we-do {
    margin: 20px 30px !important;
  }
  .testing-content {
    font: normal normal 18px/36px "Poppins";
  }
}
