@import url("https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@0,300;0,400;1,300;1,400;1,700&family=Poppins:ital,wght@0,200;0,300;0,400;0,500;1,300;1,400&display=swap");

.tech-head h3 {
  text-align: center;
  font: normal normal bold 45px/1 "poppins";
  letter-spacing: 1px;
  transition: 1s all ease;
}
.tech-head p {
  text-align: center;
  font: normal normal 25px/45px "poppins";
  transition: 1s all ease;
}
.tech-head {
  margin-bottom: 3rem;
  padding-top: 21px;
}
.tech {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}
.technologies {
  background-color: #e3f2fd;
}
.tech-card {
  width: 380px;
  flex-wrap: wrap;
  padding: 45px;
  margin: 50px;
  transition: 1s all ease;
  text-align: justify;
  border: 2px solid #90a4ae;
}
.tech-card:hover {
  background-color: black;
  color: #e7e7e7;
  transition: 0.7s all ease-in;
}
.web-logo {
  display: flex;
  justify-content: center;
  font-size: 130px;
  padding: 50px;
  border-radius: 15%;
  /* background-color: #e7e7e7; */
  border: 1px solid #90a4ae;
}
.card-title {
  text-align: center;
  font: normal bold 30px/1 "poppins";
  letter-spacing: 1px;
}
.card-content {
  font: normal normal 20px "poppins";
  line-height: 40px;
}
.card-button {
  outline: none;
  padding: 15px 38px;
  letter-spacing: 1px;
  cursor: pointer;
  font: normal normal 18px "poppins";
  border: 1px solid #e7e7e7;
  transition: 1s all ease;
  background-color: black;
  color: white;
}
.card-button:hover {
  background-color: black;
  color: white;
  border: none;
}
.button-main {
  display: flex;
  justify-content: center;
  margin-top: 65px;
  margin-bottom: 30px;
}

/* Media Querry */

@media only screen and (max-width: 760px) {
  .tech-head h3 {
    font: normal bold 35px/1 "poppins";
  }
  .tech-head p {
    font: normal normal 18px/36px "poppins";
    margin: 10px;
  }
  .tech-card {
    padding: 20px;
    margin: 20px;
    border: 1px solid #e7e7e7;
  }
  .card-content {
    font: normal normal 16px/32px "Poppins";
  }
  .card-title {
    font: normal bold 26px/36px "Poppins";
  }
}
