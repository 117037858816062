@import url("https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@0,300;0,400;1,300;1,400;1,700&family=Poppins:ital,wght@0,200;0,300;0,400;0,500;1,300;1,400&display=swap");

.react-main {
  height: auto;
  /* background-image: linear-gradient(to right, #c33764, #1d2671); */
  /* background-image: linear-gradient(to right, #d3d3d3, #2d3436); */
  flex-direction: row;
  background-color: rgb(26, 25, 25);
  padding: 30px;
}
.title-head {
  text-align: center;
  font: normal normal bold 30px/1 "poppins";
  color: white;
  letter-spacing: 2px;
}

.container {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  align-items: center;
  justify-content: center;
  gap: 5rem;
  margin-bottom: 2rem;
}
.container .card {
  max-width: 300px;
  height: 165px;
  margin: 30px 10px;
  padding: 20px 15px;
  box-shadow: 0 10px 20px rgba(214, 184, 184, 0.5);
  transition: 0.3s ease-in-out;
  letter-spacing: 2px;
  font: normal 15px/22px "poppins";
  text-transform: capitalize;
}
.container .card:hover {
  height: 450px;
}
.container .card .imgContainer {
  position: relative;
  width: 250px;
  height: 100px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 15px;
  left: 20px;
  z-index: 1;
  box-shadow: 0 5px 20px rgba(177, 152, 152, 0.2);
}

.container .card .content {
  position: relative;
  padding: 10px 15px;
  text-align: center;
  color: rgb(243, 235, 235);
  line-height: 22px;
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
  transition: 0.3s ease-in-out;
}
.container .card:hover .content {
  visibility: visible;
  opacity: 1;
  margin-top: 30px;
  margin-bottom: 15px;
  transition-delay: 0.3s;
}

@media (min-width: 760px) {
  .container {
    background-color: none;
  }
}
