@import url("https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@0,300;0,400;1,300;1,400;1,700&family=Poppins:ital,wght@0,200;0,300;0,400;0,500;1,300;1,400&display=swap");

.job-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.job-title {
  font: normal bold 40px/1 "Poppins";
  letter-spacing: 1px;
}
.job-apply {
  padding: 20px 30px;
  border: none;
  outline: none;
  background-color: black;
  color: white;
  font: normal 17px/1 "Poppins";
  letter-spacing: 1px;
  cursor: pointer;
  text-decoration: none;
}
.about-role {
  margin-bottom: 55px;
}
/* Job Body Section */

.job-main {
  background-image: url(../Assets/background.jpg);
  background-repeat: no-repeat;
  background-size: 2500px;
  padding: 2rem 14rem;
}

.job-sub-title {
  font: normal bold 28px/1 "Poppins";
  letter-spacing: 1px;
}
.job-content {
  font: normal 21px/40px "Poppins";
}
.job-body ul li {
  font: normal 21px/1 "Poppins";
  margin-bottom: 25px;
}

/* Media Querry */
@media only screen and (max-width: 760px) {
  .job-main {
    padding: 10px;
  }
  .job-title {
    font: normal bold 20px/30px "Poppins";
  }
  .job-apply {
    padding: 10px 20px;
    font: normal 12px/18px "Poppins";
  }
  .job-sub-title {
    font: normal bold 22px/1 "Poppins";
  }
  .job-content {
    font: normal 16px/35px "Poppins";
  }
  .job-body ul li {
    font: normal 16px/1 "Poppins";
  }
  .job-main {
    background-repeat: repeat-y;
    background-size: cover;
  }
}
