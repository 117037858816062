@import url("https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@0,300;0,400;1,300;1,400;1,700&family=Poppins:ital,wght@0,200;0,300;0,400;0,500;1,300;1,400&display=swap");
.home-head {
  margin: 60px;
}
.Home {
  background-image: url(../Assets/backdrop.png);
  background-position-y: 900px;

  background-size: 1200px;
  background-repeat: repeat-x;
}
.home-img {
  width: 100%;
  object-fit: cover;
  height: 60vh;
  border-bottom-right-radius: 150px;
}
.home-title {
  font: normal normal bold 45px/1 "poppins";
  text-align: center;
  letter-spacing: 1px;
}
.home-head p {
  font: normal normal 24px/40px "poppins";
  text-align: center;
}
.home-service-card {
  display: flex;
  flex-wrap: wrap;
  border: 3px solid #e7e7e7;
  margin: 30px;
  min-height: 750px;
  padding: 50px;
  justify-content: space-evenly;
  border-radius: 10px;
}
.card-one,
.card-two,
.card-three,
.card-four {
  width: 250px;
  height: auto;
  border: 3px solid #e7e7e7;
  border-radius: 15px;
  text-align: center;
  padding: 30px;
  cursor: pointer;
}
.card-two,
.card-four {
  align-self: flex-end;
}
.card-one,
.card-three {
  align-self: flex-start;
}
.app-logo {
  font-size: 70px;
  color: black;
  border-radius: 40px;
  background-color: white;
  padding: 17px;
}
.service-card-title {
  font: normal normal bold 24px "poppins";
}
.service-card-content {
  font: normal normal 20px/40px "poppins";
}
.project-client-section {
  display: flex;
  flex-wrap: wrap;
  height: auto;
  background-color: #1a237e;
  color: white;
  margin: 7rem 6rem;
  justify-content: center;
  align-items: center;
  justify-content: space-around;
  border-radius: 120px;
  padding: 50px;
  gap: 20px;
}
.project-logo {
  font-size: 65px;
  color: #e7e7e7;
  padding: 20px;
  border: 2px solid #e7e7e7;
  border-radius: 50px;
}
.project-card-one,
.project-card-two,
.project-card-three,
.project-card-four {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  flex-wrap: wrap;
}
.project-card-one,
.project-card-two,
.project-card-three,
.project-card-four p {
  margin-top: -5px;
  font: normal normal 26px/1 "poppins";
}
.project-card-content {
  font: normal normal bold 25px/1 "poppins";
}
.tech-home-main {
  background-image: url(../Assets/backdrop.png);
  background-position: 800px;
  background-size: 1100px;
  background-repeat: no-repeat;
}
.tech-content {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 30px;
}
.tech-card-one {
  height: auto;
  border: 1px solid #e7e7e7;
  padding: 50px;
  border-radius: 15px;
  text-align: center;
  margin-bottom: 30px;
  margin-top: 30px;
}
.tech-content-logo {
  font-size: 80px;
  box-sizing: border-box;
  width: 100%;
  background-color: #e7e7e7;
  padding: 55px;
  border-radius: 50%;
}
.tech-content h4 {
  font: normal normal bold 25px "poppins";
  margin-top: 4rem;
  letter-spacing: 1px;
}
.tech-btn {
  text-align: center;
  margin-bottom: 7rem;
  margin-top: 5rem;
}

.tech-view {
  padding: 15px 55px;
  cursor: pointer;
  background-image: linear-gradient(to left, #c33764, #1d2671);
  color: #e7e7e7;
  letter-spacing: 1px;
  border-radius: 35px;
  border: none;
  font: normal normal 22px "poppins";
  transition: 0.4s all ease-in-out;
}
.tech-view:hover {
  color: white;
  background-color: black;
}
.links {
  text-decoration: none;
  color: black;
}
.links:hover {
  color: #1a237e;
  transform: scale(1.1);
  transition: 0.5s all ease-in-out;
}

/* Media Querry */
@media only screen and (max-width: 760px) {
  .home-title {
    font: normal normal bold 35px/1 "poppins";
  }
  .home-head p {
    font: normal normal 18px/36px "poppins";
  }
  .home-service-card {
    gap: 20px;
    padding: 25px;
    border: 1px solid #e7e7e7;
  }
  .app-logo {
    padding: 0px;
  }
  .service-card-title {
    font: normal bold 18px/28px "poppins";
  }
  .project-logo {
    padding: 10px;
  }
  .service-card-content {
    font: normal normal 16px/28px "poppins";
  }
  .project-card-content {
    font: normal bold 18px/1 "poppins";
  }
  .project-card-one,
  .project-card-two,
  .project-card-three,
  .project-card-four p {
    font: normal normal 20px/36px "poppins";
    gap: 10px;
    text-align: center;
  }
  .project-client-section {
    /* margin-left: 50px; */
    margin: 0 50px;
  }
  .card-one,
  .card-three,
  .card-two,
  .card-four {
    border: 1px solid #e7e7e7;
  }

  .tech-card-one {
    padding: 25px;
    margin: 0;
  }
  .tech-content-logo {
    padding: 30px;
    background: none;
  }
  .client-main {
    display: none;
  }
}
