@import url("https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@0,300;0,400;1,300;1,400;1,700&family=Poppins:ital,wght@0,200;0,300;0,400;0,500;1,300;1,400&display=swap");

.mobile-dev-top-section {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 30px;
}
.mobiledev {
  background: url(../Assets/background.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  padding-bottom: 2rem;
}
.mobile-dev-main {
  width: 350px;
  border: 2px solid #e7e7e7;
  border-radius: 15px;
  padding: 30px;
  margin: 10px;
}
.mobile-img {
  background-color: #1a237e;
  font-size: 76px;
  padding: 10px;
  color: white;
  border-radius: 10px;
  text-align: center;
  margin-bottom: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.mobile-title {
  font: normal normal bold 26px/34px "poppins";
  letter-spacing: 1px;
  text-align: center;
}
.mobile-content {
  font: normal normal 20px/40px "poppins";
  text-align: justify;
}
.mobile-img-two {
  background-color: #1a237e;
  font-size: 70px;
  padding: 10px;
  color: white;
  border-radius: 10px;
  text-align: center;
  margin-bottom: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.what-we-do {
  padding: 20px;
  border-radius: 150px;
  background-color: #1a237e;
  margin: 5rem 25rem;
  border: 2px solid #7b8097;
}
.what-title {
  font: normal normal bold 30px/1 "poppins";
  color: white;
  letter-spacing: 2px;
  text-align: center;
}
.what-content {
  font: normal normal normal 20px/35px "poppins";
  color: whitesmoke;
  letter-spacing: 1px;
  text-align: center;
}
/* Media Querry */

@media only screen and (max-width: 760px) {
  .what-we-do {
    margin: 20px 30px !important;
  }
  .mobile-content {
    font: normal normal 18px/36px "Poppins";
  }
}
