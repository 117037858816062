@import url("https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@0,300;0,400;1,300;1,400;1,700&family=Poppins:ital,wght@0,200;0,300;0,400;0,500;1,300;1,400&display=swap");
.contact {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 2rem;
  margin-top: 2rem;
}
.contact-form {
  display: flex;
  gap: 30px;
  flex-wrap: wrap;
}
.contact {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 6rem;
  align-items: center;
}
.contact-left-flex {
  background-color: #e9ecef;
  padding: 60px 120px;
  height: auto;
}

.contact-title {
  font: normal normal bold 32px/1 "poppins";
  text-align: center;
  margin-bottom: 70px;
}
.contact-title-two {
  font: normal normal bold 32px/1 "poppins";
}
.contact-input {
  margin-bottom: 35px;
}
.contact-form label {
  font: normal normal bold 19px/1 "poppins";
}
.description label {
  font: normal normal bold 19px/1 "poppins";
}
.contact-form input {
  box-sizing: border-box;
  width: 350px;
  height: 70px;
  margin-top: 15px;
  border-radius: 4px;
  outline: none;
  border: 1px solid #e7e7e7;
}

.description input {
  border: 1px solid #e7e7e7;
  outline: none;
  height: 110px;
  width: 100%;
  margin-top: 15px;
  border-radius: 4px;
}

::placeholder {
  font: normal normal 16px/1 "poppins";
  padding-left: 8px;
  letter-spacing: 0.5px;
  color: gray;
}
.submit-btn {
  height: 50px;
  width: 100%;
  margin-top: 50px;
  margin-bottom: 20px;
  border: none;
  font: normal normal 17px/1 "poppins";
  background-color: rgb(72, 72, 194);
  color: white;
  letter-spacing: 2px;
  transition: 1s all ease;
}
.submit-btn:hover {
  color: black;
  background-color: white;
  border: 1px solid #e7e7e7;
}
.contact-data {
  display: flex;
  align-items: center;
  gap: 15px;
  width: fit-content;
}
.contact-data h4 {
  font: normal normal 18px/1 "poppins";
}
.contact-data-address {
  display: flex;
  align-items: center;
  gap: 15px;
  width: fit-content;
}
.contact-data-address h4 {
  font: normal normal 18px/30px "poppins" !important;
}

.contact-icon {
  font-size: 22px;
  border: 1px solid black;
  padding: 6px;
  border-radius: 50px;
}
.contact-map-section {
  width: 100%;
  text-align: center;
}

@media only screen and (max-width: 1060px) {
  .contact-left-flex {
    margin-left: 0%;
    padding: 30px;
    width: 100%;
  }
  .contact-right-flex {
    margin-right: 0%;
    width: 100%;
    margin-top: 25px;
  }
  .contact-title {
    font: normal normal bold 24px/1 "Poppins";
    letter-spacing: 0.5px;
    margin-bottom: 40px;
  }
  .contact-title-two {
    font: normal normal bold 24px/1 "Poppins";
    text-align: center;
  }
  .contact-data h4 {
    font: normal normal 16px/1 "Poppins";
    width: 100%;
  }
  .contact-data-address h4 {
    font: normal normal 16px/36px "Poppins" !important;
    width: 100%;
  }

  .contact-input {
    margin-bottom: 20px;
  }
  .contact-form {
    gap: 15px;
    justify-content: center;
    text-align: left;
  }
  .contact-form label {
    font: normal normal bold 14px/1 "Poppins";
  }
  .description label {
    font: normal normal bold 14px/1 "Poppins";
  }
  .submit-btn {
    font: normal normal 14px/1 "Poppins";
    width: 100%;
  }
  .contact {
    margin: 10px;
    gap: 1rem;
  }
}
