@import url("https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@0,300;0,400;1,300;1,400;1,700&family=Poppins:ital,wght@0,200;0,300;0,400;0,500;1,300;1,400&display=swap");

.career-top {
  background-color: #616161;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  padding: 40px;
  margin: 4rem 12rem;
  text-align: center;
  color: white;
}
.career-home-img {
  position: relative;
}
.career-home-img img {
  width: 100%;
  height: 65vh;
  object-fit: cover;
}
.career-main-title {
  position: absolute;
  top: 38%;
  left: 47%;
  font: normal bold 43px/1 "Poppins";
}
.career-main-sub {
  position: absolute;
  top: 50%;
  left: 43%;
  font: normal 23px/1 "Poppins";
}
.career-title {
  font: normal bold 40px/1 "Poppins";
  letter-spacing: 1px;
}
.career-content {
  font: normal 20px/40px "Poppins";
  letter-spacing: 1px;
}
.career-link {
  text-decoration: none;
  color: white;
}

.image-flex {
  /* margin: 4rem 12rem; */
  justify-content: center;
  margin: 5rem 0;
  display: flex;
  gap: 3rem;
}
.image-flex img {
  width: 750px;
  height: 400px;
  object-fit: cover;
  border-radius: 15px;
}

.openings-flex {
  margin: 0 12rem;
}
.open-title {
  font: normal bold 38px/1 "Poppins";
  text-align: center;
  padding: 45px 0;
  margin: 0 12rem;
  margin-bottom: 3rem;
}
.opening-card {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 40px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  margin-bottom: 4rem;
  transition: 0.5s all ease;
}
.opening-card:hover {
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
    rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}
.open-sub-title {
  font: normal 21px/1 "Poppins";
}
.open-sub-content {
  font: normal 17px/1 "Poppins";
  letter-spacing: 1px;
}
.career-link {
  border: none;
  outline: none;
  padding: 15px 55px;
  font: normal 20px/1 "Poppins";
  color: white;
  background-color: black;
  cursor: pointer;
}
/* Media Querry */
@media only screen and (max-width: 1060px) {
  .career-home-img img {
    width: 100%;
    height: 40vh;
  }
  .career-main-title,
  .career-main-sub {
    display: none;
  }
  .career-title {
    font: normal bold 25px/1 "Poppins";
  }
  .career-content {
    font: normal 18px/35px "Poppins";
  }
  .career-top {
    margin: auto;
  }
  .image-flex {
    flex-wrap: wrap;
    padding: 10px;
    gap: 1rem;
    margin: 1rem 0;
  }
  .image-flex img {
    width: 100%;
  }
  .openings-flex {
    margin: auto;
    padding: 10px;
  }
  .open-title {
    font: normal bold 25px/1 "Poppins";
    margin: auto;
  }
  .opening-card {
    flex-wrap: wrap;
    margin: 15px 0;
    padding: 10px 20px;
  }
  .career-link {
    padding: 10px 20px;
    font: normal 15px/1 "Poppins";
  }
  .open-sub-title {
    font: normal 19px/1 "Poppins";
  }
}
