@import url("https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@0,300;0,400;1,300;1,400;1,700&family=Poppins:ital,wght@0,200;0,300;0,400;0,500;1,300;1,400&display=swap");
.profile {
  background-color: #ede7f6;
  padding-bottom: 20px;
  background-image: url(../Assets/backdrop.png);
  background-position: 50px;
  background-size: 1200px;
  background-repeat: no-repeat;
}
.profile-main {
  z-index: 2;
}
.profile-backdrop {
  width: 100%;
  height: 60vh;
  object-fit: cover;
  border-bottom-right-radius: 50px;
}
.profile-left-flex {
  display: flex;
  align-items: center;
  margin: 50px;
}
.profile-left-flex h3 {
  font: normal normal bold 45px/1 "poppins";
  letter-spacing: 1px;
  text-align: center;
}
.profile-left-flex p {
  font: normal normal 22px/48px "poppins";
  text-align: center;
}
.project-card {
  width: 400px;
  height: auto;
  box-shadow: 0 9px 15px 9px rgba(4, 26, 70, 0.15);
  padding: 25px;
}
.project-image {
  display: flex;
  justify-content: center;
}
.project-card img {
  height: 180px;
  padding: 10px;
  object-fit: contain;
  background-color: white;
  margin-bottom: 3rem;
  border-radius: 10%;
}
.project-card h3 {
  font: normal bold 29px/1 "poppins";
  letter-spacing: 1px;
  text-align: center;
}
.project-card p {
  font: normal normal 20px/40px "poppins";
  text-align: justify;
}
.project-button {
  padding: 20px;
  outline: none;
  border: none;
  width: 60%;
  font: normal 18px/1 "poppins";
  letter-spacing: 1px;
  background-color: #263238;
  color: white;
  margin-top: 2rem;
  margin-bottom: 1rem;
  cursor: pointer;
}
.button-project {
  display: flex;
  justify-content: center;
}
.profile-right-flex {
  display: flex;
  gap: 10rem;
  margin: 20px;
  justify-content: center;
  margin-bottom: 4rem;
}
.project-second-section {
  display: flex;
  justify-content: center;
  gap: 10rem;
  flex-wrap: wrap;
  margin: 20px;
  margin-bottom: 2rem;
}
.project-button a {
  color: white;
  text-decoration: none;
}

/* Media Querry */

@media only screen and (max-width: 1060px) {
  .profile-left-flex h3 {
    font: normal normal bold 25px/1 "poppins";
  }
  .profile-left-flex p {
    font: normal normal 20px/35px "poppins";
    text-align: justify;
  }
  .profile-main {
    gap: 0px;
  }

  .profile-right-flex {
    flex-wrap: wrap;
  }
  .profile-main {
    flex-wrap: wrap;
  }
  .project-card {
    width: fit-content;
  }
  .project-second-section {
    gap: 20px;
  }
}
